import React from 'react';
import "./Fitness.scss";

function Fitness(){
  return (
  <div className='fitness'>
    <h1> I am the Fitness component</h1>
  </div>
  );
}

export default Fitness;