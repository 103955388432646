import { React, useState } from 'react';
import "./Contact.scss";

function Contact(){

  const [number, setNumber] = useState("");
  const [body, setBody] = useState("");
  return (
  <div className="contact">
    <container>
      <h2>Contact Us!</h2>
      <form>
        <div> 
          <input 
          type="text"
          placeholder='Title'
          value={number}
          onChange={(e) => setNumber(e.target.value)}
          >
          </input>
        </div>
        <br/>
        <div>
          <input
          type="textarea"
          placeholder='Enter Message Here'
          rows="4"
          value={body}
          onChange={(e) => setBody(e.target.value)}
          >
          </input>
        </div>
        <br/>
        <div>
          <button type="submit">
            Send
          </button>
        </div>
      </form>
    </container>
    {console.log(`Number is ${number} and the Message is ${body}`)}
  </div>
  );
}

export default Contact;